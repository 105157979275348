@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&family=Paytone+One&display=swap');
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&family=Paytone+One&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+KR:wght@100..900&family=Paytone+One&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Artifika&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

$fontI: "Inter", sans-serif;
$fontP: "Poppins", sans-serif;
$fontN: "Noto Sans KR", sans-serif;
$fontThai: "IBM Plex Sans Thai", sans-serif;
$fontR: "Righteous", sans-serif;
$fontE: "Encode Sans Expanded", sans-serif;
$fontPay: "Paytone One", sans-serif;
$fontJ: "Josefin Sans", sans-serif;
$fontA: "Artifika", serif;
.section02 {
  padding: 0 0 100px;
}

.sub-detail {
  .swiper-slide-shadow {
    display: none;
  }


  img {
    width: 100%;
  }

  .card-wrap {
    width: 90%;
    margin: 0 auto;


    .text {
      color: #767676;

      text-align: center;
      font-family: "Noto Sans KR";
      font-size: 24px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }

      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 31.2px */
      margin: 160px 0;

      .bold {
        font-weight: 800;
      }
    }
  }

  .card-background {

    &::after {
      content: "";
      background-image: url("../img/pattern.png");
      background-position: center center;
      background-repeat: no-repeat;
      object-fit: cover;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    padding: 80px 0;
    object-fit: cover;
    background-image: url("../img/swiper-swiper.png");
    //height: calc(100vh - 90px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .pattern {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    .swiper {
      .animation-img {
        position: relative;
        left: 0;
      }

      .main-image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.6);
        width: 100%;
      }
    }

    .body-swiper {
      width: calc(100% - 80px);
      padding: 0 50px;
    }

    ._body-swiper {
      width: calc(100% - 80px);
      padding: 160px 50px;
      text-align: center;

      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        z-index: 999;
        cursor: pointer;
        top: calc(50% + 20px);
        left: 0;
        color: #fff;
        font-size: 28px;

        &::after {
          content: ""
        }
      }

      .swiper-button-prev {
        transform: translate(15px, -50%);
        @media screen and (max-width: 1299px) {
          left: 0;
          transform: translate(0px, -50%);
        }
      }

      .swiper-button-next {
        transform: translate(255px, -50%);
        @media screen and (max-width: 1299px) {
          left: initial !important;
          right: 0 !important;
          transform: translate(0px, -50%);
        }
      }

      .swiper-slide {
        position: relative;

        .active-on {
          position: relative;
          opacity: 0;
          transition: 0.3s;

          .border {
            position: absolute;
            top: 0;
            right: calc(-100% + 46px);
            width: 100%;
            display: flex;
            align-items: center;
            @media screen and (max-width: 800px) {
              left: 50%;
              top: -40px;
              transform: translateX(-50%);
              width: 120%;

              hr {
                display: none;
              }
              .border-ab {
                left: 0 !important;
                width: 100% !important;

                .textLine {
                  width: 100% !important;
                }
              }
            }

            &.yellow {
              hr {
                border: 1px solid #FFE27B;
              }

              .textLine {
                & > div {
                  color: #FFE27B !important;
                }
              }
            }

            &.green {
              hr {
                border: 1px solid #D5FF7B;
              }

              .textLine {
                & > div {
                  color: #D5FF7B !important;
                }
              }
            }

            &.blue {
              hr {
                border: 1px solid #7BDFFF;
              }

              .textLine {
                & > div {
                  color: #7BDFFF !important;
                }
              }
            }

            &.pink {
              hr {
                border: 1px solid #F691FF;
              }

              .textLine {
                & > div {
                  color: #F691FF !important;
                }
              }
            }

            hr {
              width: 60px;
              margin: 0;
              transform: rotate(-35deg);

            }

            .border-ab {
              position: absolute;
              top: -40px;
              left: 60px;

              .textLine {
                width: 350px;

                & > div {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 10px;
                  color: #FFE27B;

                  font-family: $fontN;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 110%; /* 13.2px */
                  letter-spacing: -0.24px;

                  span {

                    font-family: $fontR;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%; /* 16px */
                    text-transform: uppercase;
                  }

                }
              }
            }
          }

        }
      }

      img {
        width: 100%;
        transform: scale(0.65);
        transition: 0.2s;
      }


      .swiper-slide-active {
        text-align: center;

        .main-image {
          transform: translate(-50%, -50%) scale(0.8);
        }

        .active-on {
          opacity: 1;

          .animation-img {
            animation: rotate 10s linear infinite;
          }
        }

      }
    }

  }


  .swiper {
    width: 100%;
  }

  .title-line {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;

    &.m {
      display: none;
      @media screen and (max-width: 800px) {
        display: block;


        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0px;
          @media screen and (max-width: 800px) {
            flex-direction: column;

            .bold {
              font-family: "Paytone One", sans-serif;
              font-size: 34px;
              font-style: normal;
              font-weight: 400;
              color: transparent;
              -webkit-text-stroke: 0.5px #fff;
              font-weight: bold;
              text-align: right;
            }
          }

          div {
            &:last-child {
              margin-top: 20px;
              text-align: center;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }

      }

    }

    &.pc {
      display: block;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      @media screen and (max-width: 800px) {
        flex-direction: column-reverse;
      }

      & > div {
        &:nth-child(1) {
          font-family: $fontPay;
          font-size: 64px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 64px */
          letter-spacing: -1.28px;

          @media screen and (max-width: 800px) {
            font-size: 34px;
          }

        }

        &:nth-child(2) {
          font-family: $fontN;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 16px */
          letter-spacing: -0.32px;
          text-transform: uppercase;
          line-height: 18px;
          margin-top: 7px;

          @media screen and (max-width: 800px) {
            font-size: 14px;
          }

        }
      }
    }

    .bottom {
      width: 100%;

      & > div {
        font-family: $fontPay;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        color: transparent;
        -webkit-text-stroke: 0.5px #fff;
        font-weight: bold;
        text-align: right;
        @media screen and (max-width: 800px) {
          font-size: 34px;
          text-align: center;
        }
      }

    }
  }

  .bottom-line {
    text-align: center;

    .top {
      color: var(--Schemes-On-Primary, #FFF);

      text-align: center;
      font-family: "Noto Sans KR";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 20px */
      letter-spacing: -0.4px;
      text-transform: uppercase;
    }


    .bottom {
      button {
        position: relative;
        overflow: hidden;
        margin-top: 32px;

        &:after {
          content: "";
          background: #150052;
          width: calc(100% - 1px);
          height: calc(100% - 1px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px));
          z-index: 1;
          border-radius: 50px;
        }

        img {
          position: relative;
          z-index: 3;
        }

        border: 0;
        display: inline-block;
        padding: 16px 50px;
        background: linear-gradient(90deg,
                #00ACEB 0%,
                #6CA3F5 16.67%,
                #96A0F9 33.33%,
                #DE9AFF 50%,
                #E786E7 66.67%,
                #F36DC9 83.33%,
                #FF55AB 100%);
        color: white;
        border-radius: 50px; /* 둥근 모서리 */
        padding: 16px 40px;
        font-size: 20px;
        text-align: center;
        display: inline-block;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 효과 */

        text-align: center;
        font-family: $fontN;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
        text-transform: uppercase;
      }
    }
  }


}

.custom-prev,
.custom-next {
  cursor: pointer
}

/* Studio S */
.studio-content {
  width: 990px;
  margin: 0 auto 70px;


  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    width: 90%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
  }

  .studio-title {

    font-family: $fontN;

    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    color: #767676;

    @media screen and (max-width: 1024px) {
      font-size: 38px;
    }
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }

    span {
      background: linear-gradient(100deg, #FF8C6C, #FFE3C5, #FFE7C9);
      color: transparent;
      -webkit-background-clip: text;
      font-weight: 600;
      font-family: $fontP;

    }

    img {
      width: auto !important;
      max-width: 100%;
    }
  }

  .studio-content-text {
    color: #767676;
    margin: 40px 0 76px;
    font-family: $fontN;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  .studio-image-grid {
    margin-top: 40px;

    display: flex;
    gap: 20px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }


    img {
      width: auto !important;
      max-width: 100%;

      &:nth-child(1) {
        grid-row: 1 / span 2;

      }
    }

    ._grid-image {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media screen and (max-width: 1024px) {
        flex-direction: initial;

        img {
          width: calc(50% - 20px) !important;
        }
      }
    }
  }

  .studio-image-grid-02 {
    display: flex;
    align-items: center;
    gap: 60px;
    padding: 160px 0;


    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    img {
      width: 20vw !important;
      max-width: 100%;
    }

    &.otherImage {
      img {
        width: 100% !important;
      }
    }


    .studio-text {
      color: #767676;
      font-family: "Noto Sans KR";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;

      @media screen and (max-width: 1024px) {
        font-size: 18px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }

      span {
        color: #FF8B6B;

        font-family: "Noto Sans KR";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }
    }
  }

  .studio-image-grid-03 {
    display: flex;
    gap: 60px;
    align-items: center;
    @media screen and (max-width: 1400px) {
      flex-direction: column;
    }

    img {
      width: auto !important;
      max-width: 100%;
    }
  }


  &.seconds {
    .studio-title {
      span {
        background: linear-gradient(90deg,
                #00ACEB 0%,
                #6CA3F5 15%,
                #96A0F9 30%,
                #DE9AFF 50%,
                #E786E7 70%,
                #F36DC9 85%,
                #FF55AB 100%);
        color: transparent;
        -webkit-background-clip: text;
      }
    }

    .studio-text {
      color: #767676;
      font-family: $fontN;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      width: 100%;
      text-align: center;


      span {
        color: #B353FF;

        font-weight: 700;
      }
    }

    .studio-image-grid-02 {
      padding: 160px 0 160px;

    }

    .studio-image-grid-03 {


      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: calc(100% - 40px);
        padding: 72px 20px 160px;
      }

      @media screen and (max-width: 767px) {
        width: calc(100% - 40px);
        padding: 72px 20px 160px;
      }
      margin-top: 110px;
      background-image: url("../img/background.png");
      background-size: cover;
      padding: 117px 0;

      display: flex;
      align-items: center;
      flex-direction: column;

      .text {
        color: #fff;
        font-family: "Noto Sans KR";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;

        @media screen and (max-width: 1024px) {
          font-size: 18px;
        }

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }

        & span {
          color: #F5B9FF;
        }
      }
    }
  }

  &.three {
    width: 990px;
    margin: 0 auto;

    @media screen and (min-width: 1025px) and (max-width: 1300px) {
      width: 90%;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 90%;
    }

    @media screen and (max-width: 767px) {
      width: 90%;
    }

    background-image: url("../img/background-swiper.png");
    background-repeat: no-repeat;
    background-position: center center;

    @media screen and (max-width: 1024px) {
      padding: 60px;
      background-size: 100%;

    }
    @media screen and (max-width: 767px) {
      padding: 30px;
      background-size: 100%;

    }

    .swiper-wrapper {
      align-items: center;
    }

    .studio-title {

      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }

      span {
        color: #FA6669;
        font-family: $fontP;
      }
    }

    img {
      width: auto;
    }

    .swiper {
      width: 990px;
      margin: 60px 0;
      @media screen and (min-width: 1025px) and (max-width: 1300px) {
        width: 990px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 90%;
      }

      @media screen and (max-width: 767px) {
        width: 90%;
      }

      img {
        max-width: 100%;
      }
    }


    .text-content {
      ._title {
        color: #090909;

        font-family: $fontP;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 56px;

        @media screen and (max-width: 1024px) {
          font-size: 30px;
        }
        @media screen and (max-width: 767px) {
          font-size: 26px;
          margin: 0;
        }

      }

      ._content {
        a {

          &:hover {
            color: #111;
          }

          text-decoration: none;
          color: #767676;
          margin-left: 40px;
          font-family: $fontN;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 60px;

          @media screen and (max-width: 1024px) {
            font-size: 20px;
            line-height: 38px;
          }
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 34px;
            margin: 0;
          }
        }

      }
    }
  }

  .text-bottom {
    margin-top: 128px;
    text-align: center;
    color: #767676;
    font-family: $fontN;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;

    span {
      color: #FA6669;

      text-align: center;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 35.2px */
    }
  }

}

/* Studio E */

/* entertainment S */
.entertainment {
  .entertainment-title {
    img {
      max-width: 100%;
    }


  }

  .entertainment-title-line {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, -50px);
    margin: 0 0 120px;

    @media screen and (max-width: 1024px) {
      position: relative;
      z-index: 9;
      transform: translate(0, 0px);
    }

    hr {
      width: 97px;
      height: 2px;
      border: 0;
      margin: 0;
      background: #C5E4EB;
    }

    ._text {
      border-radius: 50px;
      background: #C5E4EB;

      color: #2257A6;

      text-align: center;
      font-family: $fontN;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 33.6px */

      padding: 10px 20px;
      @media screen and (max-width: 1024px) {
        font-size: 18px;
      }


    }
  }

  .entertainment-picture {
    display: flex;
    align-items: flex-start;
    gap: 60px;

    @media screen and (max-width: 1024px) {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-direction: column;
    }

    ._picture-title {
      color: #3F3F3F;

      font-family: $fontN;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */

      @media screen and (max-width: 1024px) {
        font-size: 26px
      }

      @media screen and (max-width: 767px) {
        font-size: 16px
      }


    }

    ._picture-content {
      margin: 19px 0 100px;
      color: #767676;

      font-family: $fontN;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 24px */

      @media screen and (max-width: 1024px) {
        margin-bottom: 30px;

      }


      @media screen and (max-width: 1024px) {
        font-size: 20px
      }

      @media screen and (max-width: 767px) {
        font-size: 14px
      }

      span {
        color: #3F3F3F;
      }
    }

    img {
      width: auto !important;
      @media screen and (max-width: 1300px) {
        max-width: 100% !important;
      }
      @media screen and (max-width: 1024px) {
        max-width: 100% !important;
      }

    }

    &.logo {
      margin-top: 20px;

      img {
        @media screen and (max-width: 1024px) {
          display: block;
          margin-bottom: 30px;
        }
      }

    }

    ._picture-logo {
      display: inline-block;

      color: #3F3F3F;


      font-family: "Noto Sans KR";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  .entertainment-table {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 17px;
    margin: 170px 0 100px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 60px;
      margin-top: 70px;
    }

    & > div {

      &:nth-child(1) {
        padding-right: 17px;
        border-right: 1px solid rgba(126, 126, 126, 0.50);
        @media screen and (max-width: 1024px) {
          border-right: 0;
        }

      }
    }

    ._table-title {
      color: #767676;
      font-family: $fontN;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 1024px) {
        font-size: 20px
      }

      @media screen and (max-width: 767px) {
        font-size: 18px
      }

      span {
        color: #3F3F3F;
        font-weight: 700;
      }
    }

    & > div {
      &:nth-child(2) {
        ._table-content {
          background-color: #C5EBC9;
        }
      }
    }

    ._table-content {
      border-radius: 50px;
      background: #C5E4EB;
      padding: 20px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      color: #2257A6;
      text-align: center;
      font-family: $fontN;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      margin-bottom: 20px;

      @media screen and (max-width: 1024px) {
        font-size: 20px
      }

      @media screen and (max-width: 767px) {
        font-size: 14px
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/* entertainment S */

/* kevin S */
.kevin {
  width: 100%;
  background: linear-gradient(180deg, #002C69 0%, #00419C 50%, #376CB6 100%);
  padding: 80px 0;
  text-align: center;
  border-radius: 26px;


  .kevin-title {
    font-family: "Josefin Sans";
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;

    .color {
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      font-weight: 600;
      background: linear-gradient(90deg, #E99457 0%, #E06710 43.8%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .font {
      color: var(--Schemes-On-Primary, rgba(255, 255, 255, 0.90));
      font-family: $fontA;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
  }

  .kevin-content {
    color: rgba(255, 255, 255, 0.70);
    margin-top: 24px;
    text-align: center;
    font-family: $fontN;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 24px */

    @media screen and (max-width: 1024px) {
      font-size: 20px
    }

    @media screen and (max-width: 767px) {
      font-size: 16px
    }

    span {
      color: rgba(255, 255, 255, 0.80);
      font-family: $fontN;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;

      @media screen and (max-width: 1024px) {
        font-size: 20px
      }

      @media screen and (max-width: 767px) {
        font-size: 16px
      }

    }
  }

  .breakpoint {
    margin-top: 100px;

    img {
      &:nth-child(1) {
        display: block
      }

      &:nth-child(2) {
        display: none
      }

      &:nth-child(3) {
        display: none
      }


      @media screen and (min-width: 768px) and (max-width: 1024px) {

        &:nth-child(1) {
          display: none
        }

        &:nth-child(2) {
          display: block
        }

        &:nth-child(3) {
          display: none
        }

      }

      @media screen and (max-width: 767px) {

        &:nth-child(1) {
          display: none
        }

        &:nth-child(2) {
          display: none
        }

        &:nth-child(3) {
          display: block
        }

      }

    }


  }

  .bg_img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .text {
      color: rgba(255, 255, 255, 0.70);

      text-align: center;
      font-family: $fontN;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 20px */
      padding-bottom: 100px;


      @media screen and (max-width: 1024px) {
        font-size: 20px
      }

      @media screen and (max-width: 767px) {
        font-size: 16px
      }

    }

    img {
      width: auto;
      padding: 100px 0;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .three_title {
    padding: 40px 0 30px;
    text-align: center;

    color: rgba(255, 255, 255, 0.70);
    font-family: $fontN;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.48px;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      font-size: 20px
    }

    @media screen and (max-width: 767px) {
      font-size: 18px
    }
  }

  .three_image {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(100% - 20px);
    margin: 0px auto 0;


    @media screen and (max-width: 1024px) {
      font-size: 20px;
      flex-direction: column;
    }

    img {
      width: 100%;
    }
  }
}

/* kevin E */
/* ksquare S */
.ksquare {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 26px;

  &.studio-content {

    @media screen and (min-width: 1025px) and (max-width: 1300px) {
      width: 100% !important;
    }
    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }
  }

  .content-area {
    padding: 80px;

    @media screen and (max-width: 1024px) {
      padding: 40px;
    }

    .title {
      color: rgba(255, 255, 255, 0.90);
      font-family: "Noto Sans KR";
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 56px */


      @media screen and (max-width: 1024px) {
        font-size: 40px
      }

      @media screen and (max-width: 767px) {
        font-size: 20px
      }

      & + img {
        margin-top: 10px;
        width: 765px;
        max-width: 100%;

        @media screen and (max-width: 1024px) {
          width: 80%;
        }
        @media screen and (max-width: 767px) {
          min-width: 200px;
          width: 80%;
        }

      }
    }

  }

  .main-img {
    width: 90%;
  }

  .content {
    color: rgba(255, 255, 255, 0.90);

    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 20px */
    margin-top: 20px;

    @media screen and (max-width: 1024px) {
      font-size: 16px
    }

    @media screen and (max-width: 767px) {
      font-size: 14px
    }

  }

  .grid-image {
    width: 90%;
    margin: 120px auto 56px;

    .grid-title {

      @media screen and (max-width: 1024px) {
        text-align: center;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }

      img {
        width: 500px;
        margin-bottom: 48px;

        @media screen and (max-width: 1024px) {
          width: 50%;
        }
        @media screen and (max-width: 767px) {
          width: 80%;
          margin: 0 auto 48px;
        }
      }
    }

    .grid-img {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 30px;
      }
    }

    .grid-item {
      @media screen and (max-width: 767px) {
        text-align: center;
      }

      img {

        @media screen and (max-width: 767px) {
          width: 90%;
          margin: 0 auto;
        }
      }

      .title {
        font-family: $fontJ;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
        color: #C897EC;
        text-align: center;
        margin: 20px 0;

        @media screen and (max-width: 1024px) {
          font-size: 20px
        }

        @media screen and (max-width: 767px) {
          font-size: 18px
        }


      }

      ul {
        padding-left: 17px;

        li {
          margin-bottom: 8px;
          margin-left: 20px;
          list-style: disc;
          color: #C897EC;
          font-family: $fontJ;;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 23px;
          text-align: left;

          @media screen and (max-width: 1024px) {
            font-size: 20px
          }

          @media screen and (max-width: 767px) {
            font-size: 18px;
          }


          &::marker {
            position: relative !important;
            top: -2px;
          }
        }
      }
    }
  }

  .swiper-container {
    width: 90%;
    margin: 120px auto 0px;
    font-size: 0;


    .swiper-area {
      padding-top: 24px;
      padding-bottom: 56px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #fff;
    }

    .swiper-pagination-bullet-active {
      background-color: #fff;
    }

    .swiper-pagination-bullet {
      background-color: #fff;
    }

    img {
      width: auto !important;

      @media screen and (max-width: 1024px) {
        width: 80%;
      }
      @media screen and (max-width: 767px) {
        min-width: 200px;
        width: 80%;
      }
    }

    .swiper-slide {
      img {
        @media screen and (max-width: 1024px) {
          width: 100% !important;
        }

        @media screen and (max-width: 767px) {
          width: 100% !important;
        }
      }
    }
  }

  .banner {
    position: relative;
    text-align: center;

    img {
      width: auto;
      margin: 0 auto;


      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      @media screen and (max-width: 767px) {
        width: 90%;
      }


    }

    .banner-text {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      color: rgba(255, 255, 255, 0.80);

      font-family: $fontN;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 20px */
      br {
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
        }
      }

      @media screen and (max-width: 1024px) {
        font-size: 20px
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }

    }
  }


}

/* ksquare E */

.swiper-page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #B1B1B1;

  .swiper-button-disabled {
    opacity: 0.4;
  }

  .custom-pagination {
    display: flex;
    align-items: center;
    width: auto !important;
    transform: translate(0, -2px);
    gap: 5px;


    .swiper-pagination-current {
      color: #767676;
      transform: translate(0, -2px);
      font-family: "Roboto Condensed";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
    }

    .swiper-pagination-total {
      color: #B1B1B1;
      font-family: "Roboto Condensed";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }
  }
}

.news-content {
  img {
    margin: 40px 0 80px;
  }

  .new-category {
    display: none;
    //display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 39.6px */
    text-transform: uppercase;

    border-bottom: 3px solid #111111;

    @media screen and (max-width: 1024px) {
      font-size: 26px
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }

    svg {
      transform: rotate(45deg);

      color: #090909;

      font-family: $fontI;

    }
  }

  .new-title {
    color: #000;
    font-family: $fontJ;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px; /* 50px */
    letter-spacing: -1px;
    text-transform: uppercase;
    //margin-top: 50px;

    @media screen and (max-width: 1024px) {
      font-size: 30px;
      line-height: 40px; /* 50px */
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 30px; /* 50px */
    }

  }

  .new-content {
    color: #000;

    font-family: $fontJ;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 30px; /* 50px */
    }

  }

  .new-source {
    color: #000;

    text-align: right;
    font-family: $fontJ;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 30px; /* 50px */
    }


  }

  .linkBtn {
    font-kerning: none;
    /* C20 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */

    border-radius: 100px;
    opacity: var(--sds-size-stroke-border);

    background: var(--Basic, #333);
    text-decoration: none;
    color: #fff;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    padding: 5px 20px;

    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 30px; /* 50px */
    }

    svg {
      font-size: 30px;
    }
  }
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

