@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

$fontI: "Inter", sans-serif;
$fontL: "Lato", sans-serif;
$fontP: "Poppins", sans-serif;
$fontN: "Noto Sans KR", sans-serif;
$fontThai: "IBM Plex Sans Thai", sans-serif;

.header {
  background-color: #fff;

}

.mobile {


  .header {
    padding: 20px;
    width: calc(100% - 40px);
    background-color: #fff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    transition-duration: 0.3s;
    z-index: 11;

    svg {
      font-size: 25px;
    }

    .hamburger {
      width: 30px;
      height: 30px;
      position: relative;

      span {
        display: inline-block;
        width: 100%;
        height: 3px;
        background-color: #000;
        position: absolute;
        left: 0;
        transition-duration: 0.3s;

        &:nth-child(1) {
          top: calc(0px + 10px);
        }

        &:nth-child(2) {
          top: calc(100% - 10px);
        }
      }

      &.add {
        span {

          &:nth-child(1) {
            top: calc(0px + 15px);
            transform: rotate(40deg);
          }

          &:nth-child(2) {
            top: calc(100% - 15px);
            transform: rotate(-40deg);
          }
        }
      }
    }

    &.add {
      background-color: #000;

      svg {
        color: #fff;
      }

      .hamburger {
        span {
          background-color: #fff;
        }
      }
    }
  }

  .FixedHeader {
    opacity: 0;
    position: fixed;
    top: -100%;
    height: 0;
    width: 100%;
    overflow: hidden;
    transition-duration: 0.3s;
    z-index: 10;
    background-color: #000;

    &.on {
      display: block;
      opacity: 1;
      top: 70px;
      height: calc(100% - 70px);

      li {
        a {
          position: relative;
          display: inline-block;
          font-size: 30px;

          .text {
            opacity: 0;
          }

          .before {
            position: absolute;
            height: 50%;
            top: 0;
            left: 0;
            overflow: hidden;

            div {
              transform: translate(0px, 2px);
            }
          }

          hr {
            display: none;
          }

          .after {
            position: absolute;
            height: 50%;
            top: 50%;
            left: 0;
            overflow: hidden;

            div {
              transform: translate(0, -9px);
            }
          }

        }
      }

      .add {
        a {
          padding: 0 5px;
          font-style: italic;
          color: #fff;
          font-size: 22px;

          .before {
            width: 100%;

            div {
              transform: translate(-2px, 2px);
            }
          }

          hr {
            display: block;
            position: absolute;
            border: 0;
            height: 2px;
            top: 50%;
            left: 0%;
            transform: translate(0%, calc(-50% + 0px));
            margin: 0;
            width: 100%;
            background-color: #fff;
          }

          .after {
            width: 100%;

            div {
              transform: translate(2px, -9px);
            }
          }

        }
      }
    }
  }

}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px 40px;
  position: fixed;
  width: calc(1440px - 60px);
  left: 50%;
  transform: translate(-50%, 0);

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    width: 1025px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
  }

  top: 0;
  z-index: 9;


}

.FixedHeader {
  position: fixed;
  z-index: 9;


  li {
    padding: 4px 30px;
    font-family: $fontP;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%; /* 28.6px */
    text-transform: uppercase;
    cursor: pointer;
    color: #B1B1B1;
    @media screen and (max-width: 1024px) {
      font-size: 16px !important;
    }

    a {
      color: #B1B1B1;
      text-decoration: none;
    }

    &.add {
      color: #111;
      font-size: 22px;

      a {
        color: #111;
        font-size: 22px;
      }
    }
  }


}

.mobile-fixed {
  width: calc(100% - 40px);
  overflow: hidden;

  position: fixed;
  z-index: 9;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);

  background-color: #000;
  border-radius: 50px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 30px;

  .FixedHeader {
    position: relative;
    width: calc(100% - 20px);
    padding: 0 10px;
    font-size: 20px;

    li.add {
      color: #ff0077;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 10px;

    li {
      padding: 0;
    }
    .swiper-slide {
      display: inline-block;
      width: auto !important;
    }
  }

}

.sub-fixed {
  float: left;
  position: sticky;
  width: 260px;
  top: 110px;

  .FixedHeader {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }


  @media screen and (max-width: 1024px) {
    bottom: 20px;
    right: 20px;
    z-index: 9;
    width: 150px;
    top: initial;
    float: initial;
    position: fixed;
    .swiper-page {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100px;
  }

  img {
    width: 100%;
  }

  .fixed-card {
    width: 100%;
    left: 0;


  }

  .FixedHeader {
    position: relative;
  }
}
