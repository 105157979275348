@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

$fontI: "Inter", sans-serif;
$fontL: "Lato", sans-serif;
$fontP: "Poppins", sans-serif;
$fontN: "Noto Sans KR", sans-serif;
$fontThai: "IBM Plex Sans Thai", sans-serif;
.footer-bottom,
.footer-copy div {
  width: 1440px;
  margin: 0 auto;
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    width: 1025px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
  }

}

.scroll-button {
  position: fixed;
  bottom: 30px;
  right: 60px;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  transition-duration: 0.3s;
  z-index: 999;

  &.show {
    opacity: 1;
  }
}

footer {
  background-color: #232145;
  width: 100%;

  img {
    width: 94px;
  }

  .footer-copy {
    color: #A2A8D6;
    padding: 10px 40px 20px;
    border-top: 1px solid #A2A8D6;

    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 242.5%; /* 38.8px */
    text-transform: capitalize;
  }

  .footer-top {
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-color: #1b2129;

    a {
      color: #fff;
      font-size: 30px;
      text-decoration: none;
    }

    img {
      width: 30px;
    }
  }

  .footer-bottom {
    padding: 60px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      width: 80%;
      padding: 80px 0;
    }

    @media screen and (max-width: 767px) {
      width: 90%;
      padding: 40px 0;
    }

    .flexLine {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 40px;
      }

      .logo-address {

        display: grid;
        grid-template-columns: auto 1fr;
        gap: 40px;
        align-items: flex-start;

        @media screen and (max-width: 780px) {
          grid-template-columns: 1fr;
          gap: 10px;
        }


        .logo {
          font-family: $fontN;
          color: #Fff;
          font-size: 30px;
          position: relative;
          top: 3px;
        }

        .address {
          color: #A2A8D6;

          font-family: "Noto Sans KR";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
          text-transform: capitalize;

        }
      }

      .callBox {
        display: grid;
        grid-template-columns:  1fr 1fr;
        gap: 20px;
        justify-content: space-between;
        padding: 0 20px;


        @media screen and (max-width: 768px) {
          padding: 0;
          flex-direction: column;
          gap: 20px;
          width: calc(100% - 40px);
        }

        img {
          grid-column: 1  / span 2;
        }


        ._call {
          color: #A2A8D6;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        ._phone {
          color: #FFF;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }
      }
    }
  }
}

.re_line {

  &.up {
    .pink-btn {
      bottom: 350px;
    }
  }

  .pink-btn {
    line-height: 24px;
    text-decoration: none;

    br {
      display: none;

      @media screen and (max-width: 767px) {
        display: block;

      }

    }

    transition-duration: 0.3s;
    z-index: 9;
    position: fixed;
    right: 0%;
    bottom: 300px;

    border-radius: 200px 200px 10px 200px;
    background: #1979EA;

    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.20);
    width: 154px;
    height: 154px;
    @media screen and (max-width: 1399px) {
      bottom: 280px;
      width: 110px;
      height: 110px;
    }


    ._box-text {
      font-family: $fontN;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 22px */
      text-transform: uppercase;
      padding-top: 90px;
      margin: 0 auto;
      width: 100%;
      @media screen and (max-width: 1399px) {
        padding-top: 50px;
      }


      & div {
        &:nth-child(1) {
          text-align: right;
          font-family: $fontI;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 25drpx;
          text-transform: uppercase;
          padding-right: 10px;
          @media screen and (max-width: 1399px) {
            font-size: 14px;
          }
        }

        &:nth-child(2) {
          text-align: right;
          color: #BCFDAC;
          font-family: $fontI;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          text-transform: uppercase;
          padding-right: 10px;
          @media screen and (max-width: 1399px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }

    img {
      position: absolute;
      animation: rotate 1s ease-in-out infinite;
      width: 190px;
      top: -70px;
      right: -20px;
      @media screen and (max-width: 1399px) {
        width: 130px;
        top: -55px;
      }

    }


    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &.active {
    .pink-btn {
      right: 0%;
    }
  }
}


.box_btn {
  position: fixed;
  bottom: 60px;
  right: 0px;
  transition-duration: 0.3s;
  z-index: 9;
  cursor: pointer;

  &.up {
    bottom: 120px;
  }

  ._box_floating {
    border-radius: 200px 200px 10px 200px;
    background: #1979EA;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;

    position: relative;
    z-index: 3;
    flex-direction: column;
    width: 154px;
    height: 154px;
    @media screen and (max-width: 1399px) {
      width: 110px;
      height: 110px;
    }

    ._box-text {
      font-family: $fontN;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 22px */
      text-transform: uppercase;
      padding-top: 90px;
      width: 100%;
      @media screen and (max-width: 1399px) {
        padding-top: 50px;
      }


      & div {
        &:nth-child(1) {
          text-align: right;
          font-family: $fontI;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 25drpx;
          text-transform: uppercase;
          padding-right: 10px;
          @media screen and (max-width: 1399px) {
            font-size: 14px;
          }
        }

        &:nth-child(2) {
          text-align: right;
          color: #BCFDAC;
          font-family: $fontI;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          text-transform: uppercase;
          padding-right: 10px;
          @media screen and (max-width: 1399px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }

    img {
      position: absolute;
      width: 110px;
      animation: float 1s ease-in-out infinite;
      top: -30px;

      @media screen and (max-width: 1399px) {
        width: 80px;
      }

    }

  }


  .float_icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    transition-duration: 0.3s;
    background-color: #4FB9F5;
    border-radius: 50px;
    padding: 5px;
    width: 40px;
    height: 40px;


    .text {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);

      font-family: $fontN;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 19.8px */
      text-transform: uppercase;
      opacity: 0;
      text-align: center;

      transition-duration: 0.3s;
      color: #fff;

      @media screen and (max-width: 780px) {
        font-size: 13px;
        line-height: 15px
      }

    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;

      @media screen and (max-width: 1399px) {
        width: 40px;
      }
    }

    &:hover {
      .text {
        opacity: 1;
      }

      img {
        opacity: 0;
      }
    }


    &.view {
      width: 60px;
      height: 60px;

      @media screen and (max-width: 780px) {
        width: 40px;
        height: 40px;
      }

      &:nth-child(2) {
        top: calc(50% - 40px);
        left: -70px;
        @media screen and (max-width: 780px) {
          left: -50%;
          top: 25px;
        }

        img {
          @media screen and (max-width: 780px) {
            width: 35px;
          }
        }
      }

      &:nth-child(3) {
        top: calc(50% + 40px);
        left: -70px;
        @media screen and (max-width: 780px) {
          left: -50%;
          top: calc(50% + 30px) !important
        }
      }


    }
  }

}


@keyframes float {
  0% {
    top: -30px
  }
  50% {
    top: -40px
  }
  100% {
    top: -30px
  }
}

.footer-btn {
  @media screen and (max-width: 780px) {
    border-top: 1px solid #e1e1e1;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    width: calc(100% - 10px);
    left: 0;
    z-index: 9;
    gap: 10px;
    padding: 10px 0;
    padding-left: 10px;

    .float_icon {
      opacity: 0;
      top: inherit !important;
      bottom: 0;
      width: calc(50% - 10px) !important;
      border-radius: 0;
      position: absolute;
      height: auto !important;

      img {
        display: none;
      }

      a {
        text-decoration: none;
      }

      .text {
        position: inherit;
        opacity: 1 !important;
        padding: 5px 0;
        transform: translate(0%, 0%);
        font-size: 12px;

        br {
          display: none;
        }
      }

      &.left {
        left: 0;
      }

      &.right {
        left: 50%;
      }

      &.view {
        opacity: 1;
        bottom: calc(100% - 20px);

        &.left {
          top: inherit !important;
          left: 0;
        }

        &.right {
          top: inherit !important;
          left: 50%;
        }
      }
    }

    .scroll-button {
      position: initial;
      width: 0;

      display: flex;
      align-items: center;

      img {
        width: 100%;
      }

      &.show {
        width: 80px;
        padding-right: 10px;
      }
    }
    .re_line {
      width: 100%;

      ._box-text {
        color: #4FB9F5;
        border: 1px solid #4FB9F5;
        background-color: #fff !important;
      }
    }
    .box_btn, .pink-btn {
      position: initial;
      width: 100%;
      display: block;

      ._box-text {
        padding-top: 0;
        text-align: center;
        border: 1px solid #4FB9F5;
        background-color: #4FB9F5;
        border-radius: 3px;
        padding: 5px 10px;
        width: calc(100% - 22px);

        & div {
          text-align: center !important;
          padding: 0 !important;
          font-size: 12px !important;
          position: relative;
          top: 1px;

          &:nth-child(2) {
            display: none;
          }
        }
      }


      ._box_floating, &.pink-btn {
        background-color: #fff;
        width: auto;
        height: auto;
        border-radius: 0px 0px 0px 0px;
        background: rgba(0, 0, 0, 0);
        box-shadow: none;

        & > img {
          display: none !important;
        }

      }
    }

  }
}

footer {
  position: absolute;
  bottom: 0;
}
