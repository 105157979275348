
body {
  &::-webkit-scrollbar {
    width: 5px;
    /*스크롤바의 너비*/
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(29, 23, 23, 0.3);
    /*스크롤바의 색상*/
    background-clip: padding-box;
    //border: 3px solid transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    //background-color: rgba(29, 23, 23, 0.4);
    /*스크롤바 트랙 색상*/
    box-shadow: inset 0px 0px 2px white;
  }
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button,
input[type="button"],
input[type="submit"] {
  cursor: pointer;
}

.DefaultFont {
  //font-family: $Regular;
}

html {
  height: 100%;
}

body, #root {
  margin: 0;
  height: 100%;
}

.ClearBoth::after {
  content: "";
  display: block;
  clear: both;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.ClearBoth::after {
  content: "";
  display: block;
  clear: both;
}

section {
  min-height: 100%;
  position: relative;
}

.content-inseide {
  padding-bottom: 250px;
  position: relative;
  // padding-top: 100px;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    //padding-top: 80px;
  }

  @media screen and (max-width: 767px) {
    //padding-top: 80px;
  }
}

.wrap {
  width: 1280px;
  margin: 0 auto;

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    width: 1025px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
  }
}

.grid {
  display: grid;

  &.two {
    grid-template-columns: 1fr 1fr;
  }

  &.three {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.article-main {
  width: 1440px;

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    width: 1025px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
  }

  margin: 130px auto 0 !important;
  padding-bottom: 500px;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: 500px;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 500px;
  }

}
