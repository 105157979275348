
.main {
  width: 1280px;
  margin: 0 auto;

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    width: 1025px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90% !important;
    margin: 0 auto !important;
  }

  @media screen and (max-width: 767px) {
    width: 90% !important;
    margin: 0 auto !important;
  }

  .grid {
    position: relative;
    width: 100%;
  }

  .grid-sizer,
  .grid-item {
    width: calc(33.333% - 40px); /* 3열 레이아웃을 위해 너비 설정 */

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: calc(49.9999% - 40px);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    border-radius: 20px;
    overflow: hidden;

    &:hover {
      .item-text {
        opacity: 1;
      }
    }

    .item-text {
      position: absolute;
      opacity: 0;
      bottom: 0;
      transition-duration: 0.3s;
      height: calc(100% - 40px);
      backdrop-filter: blur(10px);
      background-color: rgba(17, 17, 17, 0.4);
      width: calc(100% - 40px);
      left: 0;
      padding: 20px 20px 20px;
      color: #fff;

      font-family: "Noto Sans KR";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 19.5px */
      text-transform: uppercase;

      .item-text-bottom {
        position: absolute;
        bottom: 20px;

      }
    }

  }

  .grid-item {
    margin-bottom: 40px;
    position: absolute; /* Isotope에서 절대 위치를 사용해 카드 배치 */
    top: 0;
    left: 0;

    &.isotope-hidden {
      opacity: 0;
      transform: scale(0.5); /* 필터링된 요소를 작게 표시하면서 숨김 */
      pointer-events: none; /* 필터링된 요소가 클릭되지 않도록 설정 */
      position: absolute; /* 레이아웃에서 제거하기 위해 설정 */
    }
  }

  .grid-item img {
    width: 100%;
    display: block;
    border-radius: 8px;
  }

}
